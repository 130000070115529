<script>
import Layout from "../../layouts/main";

export default {
  components: {
    Layout,
 

    
  },
  data() {
    return {

    };
  },
    computed: {

  },
  methods: {
    
  }
      
};
</script>

<template>
  <Layout>
      <div class="col-xl-12">
      <h5 class="title-home">Welcome<span class="dot-home">.</span></h5>
      </div>
      </Layout>
</template>
<style scoped>
.title-home{
  font-size: 40px;
  margin: 0 0 0px 0;
  color: #011c27;
  margin-bottom: 30px;
  padding-left:30px;
  font-family: nunito;
  font-weight: 600;
}
.dot-home{
  color:#247ba0;
  font-family: nunito;
  font-weight: 600;

}
.box-home{
    font-weight: 600;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
}
.text-box-home{
      font-family: nunito;
      color: #595959;
      font-weight: 600;
      padding-left:10px;
}
.second-box-home{
    margin-left:30px;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    font-weight: 600;

}
.third-box-home{
    margin-left:30px;
    background-color: #ededed;
    color: #011c27;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
          font-weight: 600;

    font-size: 20px;
}
.fourth-box-home{
    margin-left:30px;
    background-color: #ededed;
    color: #011c27;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
          font-weight: 600;

    font-size: 20px;
}
</style>